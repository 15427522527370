<template>
    <v-container row justify-center>
        <refund-form :is-show="isShowDialog" :details="details" v-on:close-dialog="close" />
        <v-dialog max-width="600px" persistent scrollable v-model="isShowDetails">
            <v-card>
                <v-card-title class="headline">
                    Details: {{details.title}}
                </v-card-title>
                <v-card-text>
                    <v-row v-for="(field, index) of getFields()" :key="index">
                        <v-col cols="12" v-if="isJSON(field[1])">
                            <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-textarea>
                        </v-col>
                        <v-col cols="12" v-else>
                            <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showRefundForm(details)" data-cy="reverse-button">
                        Reverse
                    </v-btn>
                    <v-btn color="green darken-1" text @click="close" data-cy="close-button">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
    </template>
    
    <script>
    import {
        mapGetters
    } from 'vuex';
    import RefundForm from '@/views/components/RefundForm.vue'
    export default {
        props: {
            isShow: Boolean,
            details: Object
        },
        components: {
            RefundForm
        },
        computed: {
        ...mapGetters([
                'attributes',
                'authorities'
            ]),
        },
        watch: {
            isShow: {
                handler(value) {
                    this.isShowDetails = value
                }
            },
    
            countryJson: function(newValue) {
                try {
                    let newFoo = JSON.parse(newValue);
                    this.details.item.country = newFoo;
                } catch (err) {
                    console.log("seems to be invalid json: " + err);
                    // reset:
                    this.countryJson = JSON.stringify(this.details.item.country);
                }
            },
    
            merchantCategoryCodeJson: function(newValue) {
                try {
                    let newFoo = JSON.parse(newValue);
                    this.details.item.merchantCategoryCode = newFoo;
                } catch (err) {
                    console.log("seems to be invalid json: " + err);
                    // reset:
                    this.merchantCategoryCodeJson = JSON.stringify(this.details.item.merchantCategoryCode);
                }
            },
    
            deep: true,
        },
        mounted() {
         this.countryJson = JSON.stringify(this.details.item.country);
         this.merchantCategoryCodeJson = JSON.stringify(this.details.item.merchantCategoryCode)
        },
        data() {
            return {
                countryJson: this.details.item.country ?? {},
                merchantCategoryCodeJson: this.details.item.merchantCategoryCode ?? {},
                isShowDetails: this.isShow ?? false,
                isShowDialog: false
            }
        },
        methods: {
            showRefundForm(details) {
                console.log(details.item)
                this.details.item = details.item
                this.isShowDialog = true
            },
            hasPermission(authority) {
              if(this.authorities.some(x => x.name === authority)){
                return true
              }
              return false
            },
            
            getFields() {
                const arrOfArrays = Object.entries(this.details.item);
    
                const sorted = arrOfArrays.sort((a, b) => {
                return a[0].localeCompare(b[0]);
                });
    
                return sorted;
            },
            convertCamelCase(string) {
                return string.replace(/([a-z])([A-Z])/g, '$1 $2');
            },
            close() {
                this.isShowDialog = false
                this.$emit('close-dialog', 'details')
            },
            isJSON(value) {
              return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
            },
            stringify(value) {
              if(Object.prototype.toString.call(value) === '[object Array]') {
                var items = []
                for(var index in value) {
                  if(value[index].role) {
                    items.push(value[index].role)
                  }
                }
                if(items.length > 0) {
                  return items.toString()
                }
                return JSON.stringify(value)
              } else if(Object.prototype.toString.call(value) === '[object Object]') {
                if(value.role) {
                  return value.role
                }
                return JSON.stringify(value)
              } else {
                if(value!=null) return value.toString()
              }
            }
        }
    }
    </script>
    