<template>
    <v-container row justify-center>
        <v-dialog max-width="800px" persistent v-model="isShowTransactionHistory">
            <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
            <v-card>
                <v-card-title class="headline">
                    Transaction History: {{transactionHistory.title}}
                </v-card-title>
                <!-- <v-card-text>
                    <v-row v-for="(field, index) of getFields()" :key="index">
                        <v-col cols="12" v-if="isJSON(field[1])">
                            <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-textarea>
                        </v-col>
                        <v-col cols="12" v-else>
                            <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-text-field>
                        </v-col>    
                    </v-row>
                </v-card-text> -->

                <v-card>
                    <v-data-table
                        :hide-default-header="true"
                        :headers="headers"
                        :items="displayReports"
                        :loading="loading"
                        :page="page"
                        :pageCount="numberOfPages"
                        :options.sync="options"
                        :server-items-length="totalReports"
                        :footer-props="{'items-per-page-options': [10, 20, 50]}"
                    >
                        <!-- Header template -->
                        <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{ header.text }}</span>
                            </th>
                            </tr>
                        </thead>
                        </template>

                        <!-- Body template -->
                        <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td><span class="d-flex justify-center">{{ item.id }}</span></td>
                            <td><span class="d-flex justify-center">{{ item.dateCreated }}</span></td>
                            <td><span class="d-flex justify-center">{{ item.userId }}</span></td>
                            <td><span class="d-flex justify-center">{{ item.messageStatus }}</span></td>
                            <td><span class="d-flex justify-center">{{ item.transactionStatusInformation }}</span></td>
                            <!-- <td><span class="d-flex justify-center">{{ item.acqPayload }}</span></td> -->
                            <td><span class="d-flex justify-center">
                                <v-btn fab dark x-small color="#00a8e8" class="mr-2" @click.stop="viewPayload(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                            </span></td>
                            </tr>
                        </tbody>
                        </template>

                        <!-- No results template -->
                        <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                        </template>
                    </v-data-table>
                </v-card>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close" data-cy="close-button">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
    </template>
    
    <script>
    import {
        mapGetters
    } from 'vuex';
    import API from '../../api/API';
    import DetailsForm from '@/views/components/DetailsForm.vue'
    import Loading from '@/views/components/Loading.vue'
    export default {
        props: {
            isShow: Boolean,
            loadingDialog: Boolean,
            transactionHistory: Object
        },
        components: {
            DetailsForm,
            Loading
        },
        computed: {
        ...mapGetters([
                'attributes',
                'authorities'
            ]),
        },
        watch: {
            isShow: {
                handler(value) {
                    this.isShowTransactionHistory= value
                    this.queryData()
                }
            },
            options: {
                handler() {
                    this.queryData()
                },
            },
            deep: true,
        },
        mounted() {
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Date Created',
                        value: 'dateCreated'
                    },
                    {
                        text: 'User Id',
                        value: 'userId'
                    },
                    {
                        text: 'Message Status',
                        value: 'messageStatus'
                    },
                    {
                        text: 'Transaction History Status Information',
                        value: 'transactionHistoryStatusInformation'
                    },
                    {
                        text: 'Acq Payload',
                        value: 'acqPayload'
                    },
                ],
                loading: true,
                loadingMessage: "",
                loadingFinished: false,
                isShowTransactionHistory: this.isShow ?? false,
                page: 1,
                totalReports: 0,
                numberOfPages: 0,
                options: {
                    rowsPerPage: 10
                },
                displayReports: [],
                remainderReports: [],
                isHtml: false,
                details: {
                    title: '',
                    item: {}
                },
                isShowDetails: false,
                }
        },
        methods: {
            viewPayload(item) {
                this.details.title = item.id
                this.details.item = item.acqPayload
                this.isShowDetails = true
            },
            async queryData(isSearch = false) {
                this.loading = true
                if (isSearch) {
                    this.options.page = 1
                }
                const {
                    page,
                    itemsPerPage
                } = this.options;
                let pageNumber = page - 1;
                try {
                    const limit = itemsPerPage
                    var transactionHistoryList = undefined
                    const parameters = {
                        transactionHistoryId: this.transactionHistory.item.id,
                    }
                    transactionHistoryList = await API.getTransactionHistoryById(pageNumber, limit, parameters)
                    if (!transactionHistoryList || transactionHistoryList.error) {
                        //error getting data
                        console.log(`${transactionHistoryList.error}`)
                    } else {
                        var filteredList = transactionHistoryList.data
                        console.log("Filtered List", filteredList)
                        if (page > 1 && this.remainderReports.length > 0) {
                            filteredList = this.remainderReports.concat(filteredList)
                        }
                        this.totalReports = transactionHistoryList.totalReports
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayReports = filteredList.slice(0, end)
                        this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = transactionHistoryList.pageCount
                    }
                } catch (e) {
                    console.log(e)
                }
                this.loading = false
            },
            close() {
                this.isShowTransactionHistory = false
                this.$emit('close-dialog', 'transactionHistory')
            },
            closeDialog(type) {
                if (type === 'loading') {
                    this.loadingDialog = false
                    this.queryData;
                } else if (type === 'details') {
                    this.details.title = ''
                    this.details.item = {}
                    this.isShowDetails = false
                } else {
                    this.loadingDialog = false
                }
            },
        }
    }
    </script>
    