<template>
    <v-layout row justify-center>
        <v-dialog v-model="isShowDialog" persistent max-width="600px">
           <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage"/>
            <v-card ref="formCard">
                <v-card-title>
                    <span class="headline">Reason Form</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="formEntry">
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-autocomplete
                                        v-model="reasonCategoryCode"
                                        :items="reasonCategoryCodeList"
                                        item-text="reason"
                                        item-value="reasonCode"
                                        label="Reason*"
                                        required :rules="[rules.requiredField]"
                                        data-cy="form-merchant-category-code"
                                        return-object
                                    ></v-autocomplete>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text v-on:click="send" data-cy="send-button">Send</v-btn>
                    <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
    </template>
    
    <script>
    import API from '../../api/API';
    import Loading from '@/views/components/Loading.vue'
    export default {
        props: {
            isShow: Boolean,
            formType: String,
            loadingDialog: Boolean,
            details: Object,
        },
        components: {
            Loading
        },
        computed: {
            populatedForm() {
                return {
                    id: this.details.item.terminalId,
                    amount: this.details.item.amount,
                    reason: this.reasonCategoryCode.reason,
                    reasonCode: this.reasonCategoryCode.reasonCode,
                    batchNumber: this.details.item.batch,
                    stan: this.details.item.systemTraceAuditNumber
                }
            }
        },
        watch: {
            reasonCategoryCode: {
                handler(newVal) {
                    this.reason = newVal.reason;
                    this.reasonCode = newVal.reasonCode;
                },
                deep: true
            },
            isShow: {
                handler(value) {
                    this.isShowDialog = value
                }
            },
            deep: true,
        },
        data() {
            return {
                reasonCategoryCode: "",
                reason: "",
                reasonCode: "",
                isShowDialog: this.isShow ?? false,
                loading: true,
                loadingMessage: "",
                loadingDialog: false,
                loadingFinished: false,
                isHtml: false,
                reasonCategoryCodeList: [
                    { reason: 'Void initiated by customer / cardholder', reasonCode: '00' },
                    { reason: 'Merchant cannot complete the transaction with the cardholder', reasonCode: '01' },
                    { reason: 'An error occurred in the merchant system and/or the terminal used', reasonCode: '02' },
                    { reason: 'Void due to communication failure or system timeout', reasonCode: '03' },
                    { reason: 'Card was pulled out from POS terminal before transaction completion', reasonCode: '04' },
                ],
                rules: {
                    requiredField: v => !!v ||v == 0 || "Required field.",
                }
            }
        },
        methods: {
            close() {
                this.isShowDialog = false
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
                this.$emit('close-dialog', this.formType)
            },
            send() {
                if (this.$refs.formEntry.validate()) {
                    this.sendRefundRequest(this.populatedForm)
                }
            },
            resetForm() {
                this.$refs.formEntry.reset()
            },
            async sendRefundRequest(value) {
                this.loadingDialog = true
                this.loadingFinished = false
                this.isHtml = true
                this.loadingMessage = `Processing Request, Please Wait.`
                    setTimeout(async () => {
                        const createResponse = await API.refundCardPayment(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error || createResponse.status === "Failed") {
                            this.isHtml = false
                            if(message !== "") {
                                this.loadingMessage = createResponse.message
                            } else {
                                this.loadingMessage = createResponse.status
                            }
                            this.showFormDialog = true
                        } else {
                            this.loadingMessage = `Request Successful.`
                            this.$refs.merchantParametersForm.resetForm()
                        }

                    }, 2000);
            },
        }
    }
    </script>
    